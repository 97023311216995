<template>
  <section>
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mb-3">
          <h2 class="font-weight-800 text-transparencia">
            <!-- <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i></a> -->
            Noticias
          </h2>
        </div>
      </div>
      <div class="d-flex justify-content-center flex-wrap">
        <template v-for="(noticia, index) in noticias">
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3" :key="index">
            <div class="card card-frame card-owework border hover-shadow-9 transition-hover">
              <div class="card-img-top text-center">
                <router-link :to="'/home/noticia/' + noticia.noticiaId">
                  <img class="img-fluid rounded cimage-slider" :src="`${filesUrl + noticia.rutaImagen}`" :alt="noticia.titulo" />
                </router-link>
              </div>
              <div class="card-body" style="height:8.55rem !important;">
                <!-- <small class="text-light fs-12" :datetime="noticia.fechaNoticia"> {{ noticia.fechaNoticia | date }} </small> -->
                <router-link :to="'/home/noticia/' + noticia.noticiaId">
                  <h6 class="card-title fs-15 text-transparencia text-center" style="height:4rem !important;overflow:hidden">{{ noticia.titulo }}</h6>
                </router-link>
              </div>
              <div class="card-footer text-right pt-0">
                <router-link class="fs-13 fw-600 anim-link-2 anim-link-2-primary" :to="'/home/noticia/' + noticia.noticiaId"><i class="fa fa-search-plus ml-1"></i> Leer más</router-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";

export default {
  name: "view-noticias",
  metaInfo: {
    title: "Inicio",
  },
  components: { layoutBreadcrumb },
  data: () => ({
    noticias: [],
  }),
  methods: {
    fetchNews: async function() {
      this.$http.get(this.apiRoute("Common", "ListNoticiasAll")).then(function(response) {
        if (!response.body.error) {
          this.noticias = response.body.data;
        }
      });
      //startSlider("slider-noticias",[4,3,3,2,2,1]);
    },
  },
  mounted: async function() {
    this.fetchNews();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Noticias", url: "/home/noticias", icon: "", active: true },
    ];
  },
};
</script>
